<template>
  <section class="lp-space">
    <div class="wrap">
      <div class="flex-cover">
        <div class="title">
          <img src="@/assets/lp/t002.webp" alt="GOOD！" class="title-img tb-pc-only">
          <img src="@/assets/lp/t002sp.webp" alt="GOOD！" class="title-img sp-only">
        </div>
        <div class="results">
          <div v-for="(result, index) in results" :key="result.number">
            <div class="result" :class="{ 'last-item': index === results.length - 1 }">
              <h3 class="result-number">{{ result.number }}</h3>
              <div class="result-content">
                <div class="sp-title">
                  <h3 class="result-number">{{ result.number }}</h3>
                  <h4 v-html="result.title"></h4>
                </div>
                <p>
                  {{ result.content }}
                </p>
                <img :src="result.imgPath" :alt="result.iimgAlt" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ResultVue",
  data() {
    return {
      results: [
         {
          number: "01",
          title: "岐阜市<span style='color: var(--sub2); margin: 0 0.5rem;'>×</span>事務系職種",
          content: "月の応募件数が10件→30件へ増加。有料求人媒体を使用することなく、毎月30件程度の応募を獲得して、本当にほしい人材だけを採用できる状態へ。",
          imgPath: require('@/assets/lp/result_001.webp'),
          iimgAlt: "aaa"
        },
        {
          number: "02",
          title: "岐阜市<span style='color: var(--sub2); margin: 0 0.5rem;'>×</span>美容サロン",
          content: "有料求人媒体にかける費用は同じままで応募件数が倍増。そのため、応募獲得コストからこれまでの半分へ。",
          imgPath: require('@/assets/lp/result_002.webp'),
          iimgAlt: "aaa"
        },
        {
          number: "03",
          title: "岐阜市<span style='color: var(--sub2); margin: 0 0.5rem;'>×</span>高級飲食店",
          content: "有料求人媒体にお金をかけても応募がない。採用しても離職してしまう。この状態から、有料求人媒体にお金をかけることなく、安定的に応募を獲得して、自社に合う辞めない人材を採用できる状態へ。",
          imgPath: require('@/assets/lp/result_003.webp'),
          iimgAlt: "aaa"
        },
        {
          number: "04",
          title: "岐阜市<span style='color: var(--sub2); margin: 0 0.5rem;'>×</span>ドライバー",
          content: "応募が全くと言っていいほどない状態から、有料求人媒体にお金をかけることなく、年間で数件の応募を獲得して採用できる状態へ。",
          imgPath: require('@/assets/lp/result_004.webp'),
          iimgAlt: "aaa"
        },
        {
          number: "05",
          title: "羽島郡<span style='color: var(--sub2); margin: 0 0.5rem;'>×</span>福祉施設",
          content: "新規開設まで、求人について何をしていいかもわからない状態から、求人の仕組みづくりを全面支援。結果として、新規開業のスタッフを無事獲得。",
          imgPath: require('@/assets/lp/result_005.webp'),
          iimgAlt: "aaa"
        },
        {
          number: "06",
          title: "福岡<span style='color: var(--sub2); margin: 0 0.5rem;'>×</span>営業",
          content: "月に4件しかない応募から、倍となる月8件程度の応募を獲得できる状態へ。結果として事業拡大のペースアップに成功。",
          imgPath: require('@/assets/lp/result_006.webp'),
          iimgAlt: "aaa"
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  section {
    background-color: var(--sub3);
    .wrap {
      .flex-cover {
        .title {
          width: 35%;
          margin-bottom: auto;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
          .title-img {
            width: 80%;
            @media screen and (max-width: 767px) {
              width: 100%;
            }
          }
        }
        .results {
          width: 58%;
          @media screen and (max-width: 767px) {
            width: 100%;
            padding-top: 20px;
          }
          .result {
            display: flex;
            justify-content: space-between;
            margin-bottom: 80px;
            @media screen and (max-width: 767px) {
              margin-bottom: 40px;
            }
            .result-number {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 12%;
              aspect-ratio: 1/1;
              font-size: 40px;
              border-radius: 30%;
              color: var(--white);
              background-color: var(--main);
              margin-bottom: auto;
              @media screen and (max-width: 1100px) {
                display: none;
              }
            }
            .result-content {
              width: 83%;
              @media screen and (max-width: 1100px) {
                width: 100%;
              }
              .sp-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                letter-spacing: 0.3rem;
                margin-bottom: 15px;
                @media screen and (max-width: 768px) {
                  font-weight: 600;
                  letter-spacing: 0.2rem;
                  margin-bottom: 10px;
                }
                .result-number {
                  display: none;
                  @media screen and (max-width: 1100px) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 20%;
                    aspect-ratio: 1/1;
                    font-size: 30px;
                    letter-spacing: 0rem;
                    color: var(--white);
                    border-radius: 20px;
                    background-color: var(--main);
                    margin-bottom: auto;
                    @media screen and (min-width: 768px) and (max-width: 1100px) {
                      width: 15%;
                    }
                  }
                }
                h4 {
                  width: 75%;
                  font-size: 28px;
                  margin: 0;
                  line-height: 2.5rem;
                  @media screen and (min-width: 768px) and (max-width: 1100px) {
                    width: 80%;
                    font-size: 30px;
                  }
                  @media screen and (max-width: 768px) {
                    font-size: 22px;
                  }
                }
              }
              h4 {
                font-size: 28px;
                margin-bottom: 10px;
              }
              p {
                line-height: 2rem;
                margin-bottom: 10px;
              }
              img {
                margin-top: 20px;
                @media screen and (max-width: 768px) {
                  margin-top: 10px;
                }
              }
            }
          }
          .last-item {
            margin-bottom: 0;
          }
        }
      }
    }
  }
</style>